import { useContext, useEffect, useState } from "react";

import Styles from "./question-details.module.css";

import userImage from "../../assets/images/user.png";
import { QuestionsContext } from "../../contexts/QuestionsContext";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../utils/utlity";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";

/**
 * Questions details page
 *
 * It takes question if from path param and displays the detailed view of question
 */
export default function QuestionDetails() {
  let { id } = useParams();
  let { questions, setQuestions } = useContext(QuestionsContext);
  const [question, setQuestion] = useState(null);

  let navigate = useNavigate();

  let initialized = false;

  /**
   *   Filters active question from the questions
   *    Increments number of views for the question
   */
  useEffect(() => {
    //console.log("detail page");
    const questionId = +id;
    let quest = questions.filter((item) => item.id === questionId)[0];

    /**
     * Don't have question for id then redirect to questions screen
     */
    if (!quest || !quest.id) {
      navigate("/questions");
    } else if (!initialized) {
      quest.views += 1;
      initialized = true;
      setQuestion(quest);
    }
  }, []);

  /**
   * Up voting logic
   */

  const upVote = () => {
    let item = JSON.parse(JSON.stringify(question));
    item.votes += 1;
    setQuestion(item);
    let quests = [];
    questions.forEach((item) => {
      if (item.id == id) {
        item.votes += 1;
      }
    });
  };

  /**
   * downvoting logic
   */

  const downVote = () => {
    let item = JSON.parse(JSON.stringify(question));
    item.votes -= 1;
    questions.forEach((item) => {
      if (item.id == id) {
        item.votes -= 1;
      }
    });
    setQuestion(item);
  };

  return (
    <>
      {question && (
        <>
          <div className={Styles.mainCont}>
            <h2 className={Styles.title}>{question.question}</h2>
            <p>
              <em style={{ marginRight: 5 }}>
                Asked {question.postedDate && formatDate(question.postedDate)}
              </em>
              <em>Viewed {question.views} time(s)</em>
            </p>
            <div className={Styles.body}>
              <div className={Styles.voting}>
                <button
                  style={{
                    width: 30,
                    background: "#fff",
                    border: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => upVote()}
                >
                  <FaChevronUp style={{ fontSize: 19 }} />
                </button>
                <span>{question.votes}</span>
                <button
                  style={{
                    width: 30,
                    background: "#fff",
                    border: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => downVote()}
                >
                  <FaChevronDown style={{ fontSize: 19 }} />
                </button>
              </div>
              <div className={Styles.rightBody}>
                <div dangerouslySetInnerHTML={{ __html: question.body }}></div>
                <div className={Styles.tags}>
                  {question.tags &&
                    question.tags.length > 0 &&
                    question.tags.map((tag) => (
                      <span className={Styles.tag}>{tag}</span>
                    ))}
                </div>
              </div>
              <div className={Styles.createdBy}>
                <div className={Styles.userCont}>
                  <em>Asked {formatDate(question.postedDate)}</em>
                  <div className={Styles.avatarCont}>
                    <img className={Styles.avatar} src={userImage} />
                    <div>
                      <h5 className={Styles.userName}>Test User</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
