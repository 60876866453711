import { useContext, useRef, useState } from "react";

import { CKEditor } from "ckeditor4-react";

import { useForm } from "react-hook-form";

import Styles from "./publish.module.css";
import { QuestionsContext } from "../../contexts/QuestionsContext";
import { useNavigate } from "react-router-dom";

/**
 *  New Question publish logic
 */
export default function PublishQuestion() {
  let [value, setValue] = useState("");

  const [editorData, setEditorData] = useState("");

  //const history = useHistory();
  let navigate = useNavigate();

  let { questions, setQuestions } = useContext(QuestionsContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  value = "set content";

  /**
   *
   * @param {*} data
   *
   * Reads form data
   * update questions context
   * navigates to questions screen
   */

  const submitForm = (data) => {
    let questionsCopy = JSON.parse(JSON.stringify(questions));
    questionsCopy.push({
      id: questionsCopy.length + 1,
      question: data.title,
      answers: null,
      body: editorData,
      postedDate: new Date(),
      votes: 0,
      views: 0,
      tags: data.tags.split(","),
    });
    setQuestions(questionsCopy);
    navigate("/questions");
  };

  return (
    <div className={Styles.mainCont}>
      <h1 className={Styles.heading}>Ask a public question</h1>
      <form onSubmit={handleSubmit((data) => submitForm(data))}>
        <div className={Styles.formField}>
          <h3 className={Styles.fieldTitle}>Title</h3>
          <em style={{ marginBottom: 5, marginLeft: 2 }}>
            Be specific and imagine you're asking a question to another person
          </em>
          <input
            className={Styles.field}
            {...register("title", { required: true })}
          />
        </div>
        <div className={Styles.formField}>
          <h3 className={Styles.fieldTitle}>Body</h3>
          <em style={{ marginBottom: 5, marginLeft: 2 }}>
            Include all the information someone would need to answer your
            question
          </em>
          <CKEditor
            className={Styles.editorField}
            onChange={(event, editor) => {
              const data = event.editor.getData();
              setEditorData(data);
            }}
            initData=""
          />
        </div>

        <div className={Styles.formField}>
          <h3 className={Styles.fieldTitle}>Tags</h3>
          <em style={{ marginBottom: 5, marginLeft: 2 }}>
            Add upto 5 tags to describe what your question is about.Enter tags
            comma separated
          </em>
          <input
            className={Styles.field}
            {...register("tags", { required: true })}
          />
        </div>

        <input type="submit" className={Styles.submit} />
      </form>
    </div>
  );
}
