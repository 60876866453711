import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Questions from "./pages/questions/questions";
import PublishQuestion from "./pages/publish-questions/publish-question";
import { useState } from "react";
import { QuestionsContext } from "./contexts/QuestionsContext";
import QuestionDetails from "./pages/question-details/question-details";

export default function App() {
  const [questions, setQuestions] = useState([]);
  const value = { questions, setQuestions };
  return (
    <QuestionsContext.Provider value={value}>
      <Router>
        <div className="main-cont">
          <div className={"header"}>
            <h1>Developer Community</h1>
            <nav>
              <ul>
                <li>
                  <Link to="/">Questions</Link>
                </li>
                <li>
                  <Link to="/publish">Publish</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="main-body">
            <Routes>
              <Route path="/publish" element={<PublishQuestion />} />
              <Route path="/" element={<Questions />} />
              <Route path="/questions" element={<Questions />} />
              <Route
                path="/question-details/:id"
                element={<QuestionDetails />}
              />
            </Routes>
          </div>
        </div>
      </Router>
    </QuestionsContext.Provider>
  );
}
