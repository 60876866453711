export const formatDate = (postedDate) => {
  let diffInMins = Math.floor(
    (new Date().getTime() - new Date(postedDate).getTime()) / 60000
  );
  if (diffInMins < 0) {
    return "today";
  } else {
    return diffInMins + "ms ago";
  }
};
