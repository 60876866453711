import { useContext, useState } from "react";
import ReactPaginate from "react-paginate";
import Styles from "./questions.module.css";

import userImage from "../../assets/images/user.png";
import { QuestionsContext } from "../../contexts/QuestionsContext";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/utlity";

/**
 *
 * @param {*} currentItems
 * @returns
 *
 * it takes current page items as incoming prop and diplays the articles (questions)
 *
 */
function Items({ currentItems }) {
  let navigate = useNavigate();
  return (
    <>
      {currentItems &&
        currentItems.map((question) => (
          <article className={Styles.questionCont}>
            <div className={Styles.leftCont}>
              <span className={Styles.metricCont}>{question.votes} Votes</span>
              <span className={Styles.metricCont}>
                {question.answers ? question.answers.length : 0} Answers
              </span>
              <span className={Styles.metricCont}>{question.views} Views</span>
            </div>
            <div className={Styles.rightCont}>
              <h3 className={Styles.title}>
                <a
                  className={Styles.link}
                  onClick={() => {
                    navigate("/question-details/" + question.id);
                  }}
                >
                  {question.question}
                </a>
              </h3>
              <div
                className={Styles.body}
                dangerouslySetInnerHTML={{ __html: question.body }}
              ></div>
              <div className={Styles.tags}>
                {question.tags &&
                  question.tags.length > 0 &&
                  question.tags.map((tag) => (
                    <span className={Styles.tag}>{tag}</span>
                  ))}
              </div>
            </div>
            <div className={Styles.createdBy}>
              <div className={Styles.userCont}>
                <em>Asked {formatDate(question.postedDate)}</em>
                <div className={Styles.avatarCont}>
                  <img className={Styles.avatar} src={userImage} />
                  <div>
                    <h5 className={Styles.userName}>Test User</h5>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}
    </>
  );
}

/**
 *
 * @param {*} itemsPerPage
 * @returns
 * Pagination logic here. Based on itemsPerPage deriving the current page items
 */

function PaginatedItems({ itemsPerPage }) {
  const [itemOffset, setItemOffset] = useState(0);

  let { questions, setQuestions } = useContext(QuestionsContext);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = questions.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(questions.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % questions.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

/**
 * Questions listing component
 *
 */

export default function Questions() {
  let navigate = useNavigate();

  let { questions, setQuestions } = useContext(QuestionsContext);

  return (
    <>
      <div className={Styles.mainCont}>
        <div className={Styles.titleCont}>
          <h1 className={Styles.mainHeading}>All Questions</h1>
          <a
            className={Styles.addQuestion}
            onClick={() => {
              navigate("/publish");
            }}
          >
            Add Question
          </a>
        </div>
        <p>
          {questions.length} <em>question(s)</em>
        </p>

        {questions && questions.length > 0 && (
          <PaginatedItems itemsPerPage={15} />
        )}
      </div>
    </>
  );
}
